import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function TwoRowSeparate({rowContents}) {
  return (
    <Container>
      <Row>
        {/* Auto layout columns */}
        <Col>{rowContents[0]}</Col>
      </Row>
      <hr style={{
        marginTop: '-4rem',
        marginBottom: '1rem',
      }} />
      <Row>
        <Col>{rowContents[1]}</Col>
      </Row>
    </Container>
  );
}

export default TwoRowSeparate;