import React from 'react';
import BubbleChart from '../components/BubbleChart'; // No need to add .jsx in import
import TwoRowSeparate from '../components/TwoRowSeparate'; // No need to add .jsx in import
import SearchTrendParentTabs from '../components/SearchTrends/SearchTrendsParentTabs';
import SearchTrendChildTabs from '../components/SearchTrends/SearchTrendsChildTabs';

const averageCategoryData = {
  "30days": {
    "positive": [
      {
        "label": "AI",
        "value": 16.59,
        "color": 'green',
      },
      {
        "label": "Bitcoin",
        "value": 4.345,
        "color": 'green',
      },
      {
        "label": "AVAX",
        "value": 25,
        "color": 'green',
      },
      {
        "label": "Cardano",
        "value": 4.26,
        "color": 'green',
      },
      {
        "label": "CEX",
        "value": 1.45,
        "color": 'green',
      },
    ],
    "negative": [
      {
        "label": "Metaverse",
        "value": -10.444999999999999,
        "color": 'firebrick',
      },
      {
        "label": "DeFi",
        "value": -21.426,
        "color": 'firebrick',
      },
      {
        "label": "Ethereum",
        "value": -8.39,
        "color": 'firebrick',
      },
      {
        "label": "NFT",
        "value": -30.299999999999997,
        "color": 'firebrick',
      },
      {
        "label": "BNB",
        "value": -7.223333333333333,
        "color": 'firebrick',
      },
      {
        "label": "Stablecoin",
        "value": -1.7200000000000002,
        "color": 'firebrick',
      },
      {
        "label": "Crypto",
        "value": -8.7,
        "color": 'firebrick',
      },
      {
        "label": "Wallets",
        "value": -10.38,
        "color": 'firebrick',
      }
    ],
  },
  "180days": {
    "positive": [
      {
        "label": "Bitcoin",
        "value": 18.23,
        "color": 'green',
      },
      {
        "label": "AVAX",
        "value": 33.12,
        "color": 'green',
      },
      {
        "label": "Cardano",
        "value": 12.67,
        "color": 'green',
      },
      {
        "label": "Stablecoin",
        "value": 9.72,
        "color": 'green',
      },
      {
        "label": "Wallets",
        "value": 15.38,
        "color": 'green',
      }
    ],
    "negative": [
      {
        "label": "Metaverse",
        "value": -25.44,
        "color": 'firebrick',
      },
      {
        "label": "DeFi",
        "value": -35.26,
        "color": 'firebrick',
      },
      {
        "label": "Ethereum",
        "value": -22.39,
        "color": 'firebrick',
      },
      {
        "label": "NFT",
        "value": -45.29,
        "color": 'firebrick',
      },
      {
        "label": "BNB",
        "value": -19.22,
        "color": 'firebrick',
      },
      {
        "label": "Crypto",
        "value": -28.7,
        "color": 'firebrick',
      },
      {
        "label": "AI",
        "value": -26.45,
        "color": 'firebrick',
      },
      {
        "label": "CEX",
        "value": -21.89,
        "color": 'firebrick',
      }
    ],
  },
  "360days": {
    "positive": [ 
      {
        "label": "Bitcoin",
        "value": 18.23,
        "color": "green"
      },
      {
        "label": "AVAX",
        "value": 33.12,
        "color": "green"
      },
      {
        "label": "Wallets",
        "value": 15.38,
        "color": "green"
      },
      {
        "label": "Cardano",
        "value": 12.67,
        "color": "green"
      },
      {
        "label": "Stablecoin",
        "value": 9.72,
        "color": "green"
      }
    ],
    "negative": [
      {
        "label": "BNB",
        "value": -22.22,
        "color": "firebrick"
      },
      {
        "label": "CEX",
        "value": -24.89,
        "color": "firebrick"
      },
      {
        "label": "Ethereum",
        "value": -25.39,
        "color": "firebrick"
      },
      {
        "label": "Metaverse",
        "value": -28.44,
        "color": "firebrick"
      },
      {
        "label": "DeFi",
        "value": -38.26,
        "color": "firebrick"
      },
      {
        "label": "Crypto",
        "value": -32.7,
        "color": "firebrick"
      },
      {
        "label": "NFT",
        "value": -48.29,
        "color": "firebrick"
      },
    ],
  }
}

const SearchTrendBubbles = () => {
  const reliableTabContents = [
    <TwoRowSeparate rowContents={[<BubbleChart data={averageCategoryData['360days'].positive} />, <BubbleChart data={averageCategoryData['360days'].negative} />]} />,
    <TwoRowSeparate rowContents={[<BubbleChart data={averageCategoryData['180days'].positive} />, <BubbleChart data={averageCategoryData['180days'].negative} />]} />,
    <TwoRowSeparate rowContents={[<BubbleChart data={averageCategoryData['30days'].positive} />, <BubbleChart data={averageCategoryData['30days'].negative} />]} />,
  ];

  const generalTabContents = [
    <SearchTrendChildTabs tabContents={reliableTabContents} />,
    <SearchTrendChildTabs tabContents={reliableTabContents} />,
  ];

  return (
    <div>
      <h1>Search Trends Bubbles</h1>
      
      <SearchTrendParentTabs tabContents={generalTabContents} />
      
    </div>
  );
};

export default SearchTrendBubbles;
