import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {CustomTabPanel, a11yProps} from './CustomTabPanel';


function TimelineTabs({tabContents}) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="timeline tabs" centered>
        <Tab label="12 Months" {...a11yProps(0)} />
        <Tab label="6 Months" {...a11yProps(1)} />
        <Tab label="1 Month" {...a11yProps(2)} />
      </Tabs>
    </Box>
    <CustomTabPanel value={value} index={0}>
      {tabContents[0]}
    </CustomTabPanel>
    <CustomTabPanel value={value} index={1}>
    {tabContents[1]}
    </CustomTabPanel>
    <CustomTabPanel value={value} index={2}>
    {tabContents[2]}
    </CustomTabPanel>
  </Box>
  );
}

export default TimelineTabs;