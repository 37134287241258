// BubbleChart.js
import React from 'react';
import Bubble from './Bubble';


function BubbleChart({ data }) {

  // Extract all values and find min and max
  const allValues = data.map(item => Math.abs(item.value));
  const minData = Math.min(...allValues);
  const maxData = Math.max(...allValues);

  // Define min and max size for the bubbles
  const minSize = 90; // in px
  const maxSize = 200; // in px

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '460px', margin: 0}}>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', width: '450px' }}>
        {data.map((bubbleData, index) => (
          <Bubble
            key={index}
            label={bubbleData.label}
            color={bubbleData.color}
            value={bubbleData.value}
            minData={minData}
            maxData={maxData}
            minSize={minSize}
            maxSize={maxSize}
          />
        ))}
      </div>
    </div>
  );
}

export default BubbleChart;
