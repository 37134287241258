import './App.scss';
import 'boxicons/css/boxicons.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppLayout from './components/layout/AppLayout';
import Blank from './pages/Blank';
import SearchTrendBubbles from './pages/SearchTrendsBubbles';
import CoinmarketcapBubbles from './pages/CoinmarketCapBubbles';
import RssFeedBubbles from './pages/RssFeedBubbles';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<AppLayout />}>
                    <Route index element={<Blank />} />
                    <Route path='/search_trends_bubbles' element={<SearchTrendBubbles />} />
                    <Route path='/coinmarketcap_bubbles' element={<CoinmarketcapBubbles />} />
                    <Route path='/rss_feed_bubbles' element={<RssFeedBubbles />} />
                    <Route path='/news_trends' element={<Blank />} />
                    <Route path='/social_trends' element={<Blank />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
