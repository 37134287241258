// Bubble.js
import React, { useState } from 'react';


function normalize(value, minData, maxData, minSize, maxSize) {
  return ((value - minData) / (maxData - minData)) * (maxSize - minSize) + minSize;
}

function Bubble({ label, value, color, minData, maxData, minSize, maxSize }) {
  const [isHovered, setIsHovered] = useState(false);

  
  value = Math.abs(value).toFixed(2);

   // Normalize the value to determine bubble size
   const size = normalize(parseFloat(value), minData, maxData, minSize, maxSize);

  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        backgroundColor: color,
        margin: '1px',
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: '#FFF', // White text color for contrast
        fontWeight: 'bold', // Bold text for better readability
        fontSize: '12px', // Adjust as per your design
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)', // Text shadow for better readability
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <p style={{ 
        margin: 0, 
        textAlign: 'center',
        color: 'white',
        fontSize: '14px',
      }}>{label}</p>
      <p style={{ 
        margin: 0, 
        textAlign: 'center',
        color: 'white',
        fontSize: '14px',
      }}>{Math.round(value)}%</p>
      {isHovered && (
        <div
          style={{
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'white',
            border: '1px solid black',
            padding: '5px',
            borderRadius: '5px',
            whiteSpace: 'nowrap',
            color: 'black',
            fontSize: '14px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <p style={{ margin: 0 }}>{label}</p>
          <p style={{ margin: 0 }}>Percentage change: {value}%</p>
        </div>
      )}
    </div>
  );
}

export default Bubble;